// extracted by mini-css-extract-plugin
export var about_us_page = "styles-module--about_us_page--1Xzq9";
export var bg_grey_20 = "styles-module--bg_grey_20--HMCFD";
export var blink = "styles-module--blink--SpnAk";
export var content = "styles-module--content--KT0Ca";
export var full_size_img = "styles-module--full_size_img--xv7-h";
export var prefooter = "styles-module--prefooter--BTMl6";
export var text_block_1 = "styles-module--text_block_1--hQTpM";
export var text_block_2 = "styles-module--text_block_2--AU4Rw";
export var text_block_3 = "styles-module--text_block_3--7CWpy";
export var video_bg = "styles-module--video_bg--2beuA";