import React from 'react'
import { InventionIcon, SimplicityIcon, WarmthIcon } from '../../lib/components/basics/icons'
import Icon from '../../lib/components/basics/icon'
import aboutUsHero from '../../../resources/images/hanzo/about-us/hero-about-us.webp'
import aboutUsTeam from '../../../resources/images/hanzo/about-us/about-us-team.webp'

// HERO
export const heroProps = {
    title: 'Who we are',
    description: (
        <p>
            Building software products and bringing them to the world is a rollercoaster. Boy do we
            know it.
        </p>
    ),
    hasOverlay: true,
    background: {
        image: {
            src: aboutUsHero,
            alt: 'hero-bg',
        },
    },
}

// TEXT BLOCK
export const textBlockProps = {
    title: (
        <div>
            Technology is complex.
            <br /> <b>We make it clear.</b>
        </div>
    ),
    textBlocks: [
        <div key='1'>
            <p>
                When telling the Lenses story, we could write about the collective exhale after
                fixing a tricky development issue. We could reflect on the sprint cycles that turned
                into marathons. We could look back on our early contributions to Kafka Connect with
                our open-source connectors, in 2016 when the technology’s success was still a
                glimmer on the horizon.
            </p>
        </div>,
        <div key='2'>
            <p>
                We could list the highs, the lows, the stops, starts; the features shipped. The rush
                of hitting revenue milestones. The long days leading up to a release. But for us,
                the people part of developing software has always been what it’s really about.
            </p>
        </div>,
        <div key='3'>
            <p>
                It is helping a team to eliminate ‘Kafka Fridays’ – turning sleepless weekends spent
                troubleshooting, into stress-free sign-offs. Making a difficult technology just
                work. It is glimpsing the relief of an engineer, when they can see how to power
                their application with streaming data. When that engineer is able to freely and
                independently do their work, without waiting for someone to process an endless
                backlog of data access requests.
            </p>
        </div>,
    ],
}

// FULL SIZE IMAGE
export const fullSizeImageHero = {
    background: {
        image: {
            src: aboutUsTeam,
            alt: 'full size image',
        },
    },
}

// TEXT BLOCK 2
export const textBlock2Props = {
    title: (
        <div>
            We love the comfort zone.
            <br /> <b>Then we leave it.</b>
        </div>
    ),
    textBlocks: [
        <div key='1'>
            <p>
                Most people see a company as something you build and then invite people to move
                into. At Lenses, we´ve grown differently. We explore and learn alongside our
                customers and partners every day. We try new things, see what works, and change
                direction when needed.
            </p>
        </div>,
        <div key='2'>
            <p>
                This approach isn´t just because we´re doing something new - it´s at the heart of
                who we are. Sure, it feels safe to do what we know will work. But testing new ideas?
                That´s scary. And that´s exactly what we love - solving tough problems no one has
                cracked before and turning bold ideas into reality. It’s what gets us excited.
            </p>
        </div>,
    ],
}

// TEXT BLOCK 3
export const textBlock3Props = {
    title: (
        <div>
            Same Lenses
            <br /> <b>re-architected for the future.</b>
        </div>
    ),
    textBlocks: [
        <div key='1'>
            <p>
                So when the team brought a big challenge to the table at an offsite – observability
                across a Frankenstein of different Kafka clusters and deployments – we couldn’t pass
                it up. We not only came up with a crazy idea to re-architect our product, so that
                one instance of Lenses would work across hundreds of Kafka clusters. We built it.
                It’s called Lenses 6 Panoptes. It keeps all the things users love about Lenses, but
                does them bigger, better, and more beautifully.
            </p>
        </div>,
        <div key='2'>
            <p>
                Today, we love watching the ripple of excitement when we explain to a CTO that a
                hybrid data infrastructure doesn´t have to mean data silos. When we show developers
                that they can tap into every single team’s streaming data, to build something
                spectacular, without the limits imposed by one Kafka cluster or another.
            </p>
        </div>,
        <div key='3'>
            <p>
                Our product has evolved, and so have we as a company. We’ve kept what makes us “us”,
                but our potential has grown. We hope that Lenses 6 Panoptes helps you grow your
                team’s potential too.
            </p>
        </div>,
    ],
}

// PRE-FOOTER
export const preFooterTitle = 'Our beliefs'

export const cardsDataPrefooter = [
    {
        title: 'Warmth',
        type: 'secondary' as const,
        description: ['Caring outperforms not caring, every time.'],
        icon: <Icon icon={<WarmthIcon />} size='xl' />,
    },
    {
        title: 'Simplicity',
        type: 'secondary' as const,
        description: ['Technology is complex. We make it clear.'],
        icon: <Icon icon={<SimplicityIcon />} size='xl' />,
    },
    {
        title: 'Invention',
        type: 'secondary' as const,
        description: ['We love the comfort zone. And then we leave it.'],
        icon: <Icon icon={<InventionIcon />} size='xl' />,
    },
]
