/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import * as myStyles from './styles.module.scss'
import HeroModule from '../../lib/components/modules/hero'
import {
    heroProps,
    preFooterTitle,
    cardsDataPrefooter,
    fullSizeImageHero,
    textBlockProps,
    textBlock2Props,
    textBlock3Props,
} from './constants'
import PreFooter from '../../lib/components/modules/prefooter'
import TextBlock from '../../lib/components/components/text-block'

const AboutUsPage = () => {
    const styles: any = myStyles

    return (
        <div className={styles.about_us_page}>
            <div className={styles.content}>
                <div className={styles.video_bg}>
                    <HeroModule {...heroProps} />
                </div>
                <div className={styles.text_block_1}>
                    <TextBlock {...textBlockProps} />
                </div>
                <div className={styles.full_size_img}>
                    <HeroModule {...fullSizeImageHero} />
                </div>
                <div className={styles.text_block_2}>
                    <TextBlock {...textBlock2Props} />
                </div>
                <div className={styles.text_block_3}>
                    <TextBlock {...textBlock3Props} variant='blue' />
                </div>
                <div className={styles.prefooter}>
                    <PreFooter isSmall title={preFooterTitle} cardsData={cardsDataPrefooter} />
                </div>
            </div>
        </div>
    )
}

export default AboutUsPage
