import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import Layout from '../../components/Layout'
import AboutUsPage from '../../hanzo/pages/about-us'

const aboutUs: React.FC = () => {
    const data = useStaticQuery(graphql`
        query AboutUsSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Company_About" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
            allContentfulLensior {
                edges {
                    node {
                        image {
                            title
                            gatsbyImageData(layout: FULL_WIDTH, width: 160, height: 160)
                            description
                        }
                        linkedin
                        name
                        jobTitle
                        department
                        description {
                            description
                        }
                    }
                }
            }
            allContentfulPressItem(sort: { order: DESC, fields: updatedAt }, limit: 3) {
                edges {
                    node {
                        title
                        link
                        image {
                            title
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout linkColor={'dark'} isTransparent={true} hasBanner={true}>
            <HeaderSEO pageSEO={pageSEO} />
            <section>
                <AboutUsPage />
            </section>
        </Layout>
    )
}
export default aboutUs
