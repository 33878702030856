import React from 'react'
import * as rawStyles from './styles.module.scss'
import { TextBlockProps } from './types'
import Wrapper from '../../basics/wrapper'

const TextBlock: React.FC<TextBlockProps> = ({ title, textBlocks, variant = 'orange' }) => {
    const styles: any = rawStyles
    return (
        <Wrapper>
            <div className={styles.c_text_block} data-variant={variant}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.text_blocks}>{textBlocks}</p>
            </div>
        </Wrapper>
    )
}

export default TextBlock
